<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const mustBeNumbers = value => {
  if (value.length === 0) {
    return true;
  }
  return value.every(
    number => number.length === 0 || number.match(/^\+[0-9]{8,}$/)
  );
};

export default {
  components: {
    SettingsSection,
  },
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      numbers: [],
    };
  },
  validations: {
    numbers: { required, mustBeNumbers },
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const { phone_numbers } = this.inbox;
      this.numbers = phone_numbers || [''];
    },
    async updateInbox() {
      try {
        this.loading = true;
        let payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            phone_numbers: this.numbers,
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        useAlert(this.$t('INBOX_MGMT.PHONE.EDIT.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(error.message);
      }
    },
  },
};
</script>

<template>
  <div class="mx-8">
    <SettingsSection
      :title="$t('INBOX_MGMT.PHONE.TITLE')"
      :sub-title="$t('INBOX_MGMT.PHONE.SUBTITLE')"
    >
      <form @submit.prevent="updateInbox">
        <div class="mb-6">
          <span v-if="label">
            {{ $t('INBOX_MGMT.PHONE.PHONE_NUMBERS.LABEL') }}
          </span>
          <input
            v-for="(number, index) in numbers"
            :key="index"
            :value="number"
            class="bg-white dark:bg-slate-900 text-slate-900 dark:text-slate-100 border-slate-200 dark:border-slate-600"
            :class="{ error: v$.numbers.$error }"
            type="text"
            :placeholder="$t('INBOX_MGMT.PHONE.PHONE_NUMBERS.PLACEHOLDER')"
            @input="numbers[index] = $event.target.value"
            @blur="v$.numbers.$touch"
          />
          <button @click.prevent="numbers.push('')" class="add-button">
            <fluent-icon icon="add" class="fluent-icon" size="16" />
          </button>
        </div>
        <woot-submit-button
          :button-text="$t('INBOX_MGMT.PHONE.UPDATE')"
          :loading="uiFlags.updateInbox"
          :disabled="v$.$invalid || uiFlags.isUpdating"
        />
      </form>
    </SettingsSection>
  </div>
</template>
