<script>
// import router from '../../dashboard/routes';
import { mapGetters } from 'vuex';

export default {
  name: 'PhoneControls',
  computed: {
    ...mapGetters({
      phoneConfigured: 'phone/configured',
      userStatus: 'phone/getUserStatus',
      status: 'phone/getStatus',
      callDuration: 'phone/getCallDuration',
      holdStatus: 'phone/getHoldStatus',
      micMuted: 'phone/getMicMuted',
      speakerSilent: 'phone/getSpeakerSilent',
    }),
    formattedDuration() {
      const minutes = Math.floor(this.callDuration / 60);
      const seconds = this.callDuration % 60;
      const minStr = minutes.toString().padStart(2, '0');
      const secsStr = seconds.toString().padStart(2, '0');
      return `${minStr}:${secsStr}`;
    },
    holdButtonIcon() {
      return this.holdStatus === 'local'
        ? 'microphone-play'
        : 'microphone-pause';
    },
    callerInfo() {
      const contact = this.$store.state.phone.contact;
      if (contact && contact.name) {
        return this.$store.state.phone.contact.name;
      }
      return this.$store.state.phone.otherPartyNumber;
    },
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('userPhoneCredentials/get');
  },
  methods: {
    connectPhone() {
      this.$store.dispatch('phone/connect');
    },
    disconnectPhone() {
      this.$store.dispatch('phone/disconnect');
    },
    answer() {
      this.$store.dispatch('phone/acceptCall');
      // router.push({ path: '/app/accounts/2/conversations/2' });
    },
    hangup() {
      this.$store.dispatch('phone/hangup');
    },
    hold() {
      this.$store.dispatch('phone/toggleHold');
    },
    toggleMute() {
      this.$store.dispatch('phone/toggleMute');
    },
    toggleSilence() {
      this.$store.dispatch('phone/toggleSilence');
    },
  },
};
</script>

<template>
  <div v-if="phoneConfigured" id="phone-controls">
    <div v-if="status === 'offline'" :class="status">
      <div class="status-indicator">
        <fluent-icon icon="phone-off" size="18" icon-lib="tabler" />
        {{ $t('PHONE_CTRLS.STATUS.OFFLINE') }}
      </div>
      <div class="action-buttons">
        <button
          class="phone-btn answer"
          :title="$t('PHONE_CTRLS.ACTIONS.CONNECT')"
          @click="connectPhone"
        >
          <fluent-icon icon="plug" size="18" icon-lib="tabler" />
        </button>
      </div>
    </div>

    <div v-if="status === 'connecting'" :class="status">
      <div class="status-indicator">
        <span v-if="userStatus === 'online'">
          <fluent-icon icon="plug" size="18" icon-lib="tabler" />
          {{ $t('PHONE_CTRLS.STATUS.CONNECTING') }}
        </span>
        <span v-else>
          <fluent-icon icon="plug-x" size="18" icon-lib="tabler" />
          {{ $t('PHONE_CTRLS.STATUS.DISCONNECTING') }}
        </span>
      </div>
    </div>

    <div v-if="status === 'connected'" :class="status">
      <div class="status-indicator">
        <fluent-icon icon="phone" />
        {{ $t('PHONE_CTRLS.STATUS.ONLINE') }}
      </div>
      <div class="action-buttons">
        <button
          class="phone-btn hangup"
          :title="$t('PHONE_CTRLS.ACTIONS.DISCONNECT')"
          @click="disconnectPhone"
        >
          <fluent-icon icon="plug-x" size="18" icon-lib="tabler" />
        </button>
      </div>
    </div>

    <div v-if="status === 'dialing'" :class="status">
      <div class="caller-info">
        {{ $t('PHONE_CTRLS.STATUS.DIALING') }}
        {{ callerInfo }}
      </div>
      <div class="action-buttons">
        <button
          class="phone-btn hangup"
          :title="$t('PHONE_CTRLS.ACTIONS.HANGUP')"
          @click="hangup"
        >
          <span class="hangup-icon">
            <fluent-icon icon="call" />
          </span>
        </button>
      </div>
    </div>

    <div v-if="status === 'ringing'" :class="status">
      <div class="caller-info">
        {{ callerInfo }}
      </div>
      <div class="action-buttons">
        <button
          class="phone-btn answer flashing"
          :title="$t('PHONE_CTRLS.ACTIONS.ANSWER')"
          @click="answer"
        >
          <fluent-icon icon="call" />
        </button>
        <button
          class="phone-btn hangup"
          :title="$t('PHONE_CTRLS.ACTIONS.HANGUP')"
          @click="hangup"
        >
          <span class="hangup-icon">
            <fluent-icon icon="call" />
          </span>
        </button>
      </div>
    </div>

    <div v-if="status === 'speaking'" :class="status">
      <div class="caller-info">
        {{ callerInfo }}
        <span class="call-duration">{{ formattedDuration }}</span>
      </div>
      <div class="action-buttons">
        <button
          class="phone-btn"
          :class="micMuted ? 'active' : ''"
          :title="$t('PHONE_CTRLS.ACTIONS.MUTE')"
          @click="toggleMute"
        >
          <fluent-icon :icon="micMuted ? 'microphone-off' : 'microphone'" />
        </button>
        <button
          class="phone-btn"
          :class="speakerSilent ? 'active' : ''"
          :title="$t('PHONE_CTRLS.ACTIONS.SILENCE')"
          @click="toggleSilence"
        >
          <fluent-icon :icon="speakerSilent ? 'speaker-mute' : 'speaker-1'" />
        </button>
        <button
          class="phone-btn hold"
          :disabled="holdStatus === 'remote'"
          :title="$t('PHONE_CTRLS.ACTIONS.HOLD')"
          @click="hold"
        >
          <fluent-icon :icon="holdButtonIcon" />
        </button>
        <button class="phone-btn hangup" @click="hangup">
          <span class="hangup-icon">
            <fluent-icon icon="call" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#phone-controls {
  padding: 12px;
  display: flex;
  justify-content: space-around;
}

.phone-btn {
  border: 1px solid #36393f;
  color: black;
  padding: 12px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.dark .phone-btn {
  background-color: #36393f;
  color: #dcddde;
  border: none;
}

.phone-btn:hover {
  transform: scale(1.1);
}

.phone-btn.answer {
  background: none;
  border: 2px solid #43b581;
}
.dark .phone-btn.answer {
  background-color: #43b581;
  border: none;
}

.phone-btn.hold {
  background: none;
  border: 2px solid #faa61a;
}
.dark .phone-btn.hold {
  background-color: #faa61a;
  border: none;
}

.phone-btn.hangup {
  background: none;
  border: 2px solid #f04747;
}
.dark .phone-btn.hangup {
  background-color: #f04747;
  border: none;
}

.phone-btn.active {
  color: #f04747;
}

.connected,
.connecting,
.offline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-indicator {
  padding: 12px;
}

.status-indicator svg {
  display: inline;
}

.status-indicator .connected svg,
.status-indicator .connecting svg {
  color: red;
}

.status-indicator .offline svg {
  color: green;
}

.status-action {
  margin-left: 20px;
}

.hangup-icon {
  transform: rotate(135deg);
}

.caller-info {
  text-align: center;
  margin-bottom: 10px;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
}

.call-duration {
  font-size: 80%;
  width: 5em;
}

.flashing {
  animation: flash 1s infinite;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
