<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';
import { mapGetters } from 'vuex';
import { messageTimestamp } from 'shared/helpers/timeHelper';

export default {
  components: {
    EmojiOrIcon,
  },
  props: {
    contactAttributes: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({}),
    customerInfo() {
      return this.contactAttributes.customer_info || {};
    },
    recentOrders() {
      return this.customerInfo.recent_orders || [];
    },
    profileUrl() {
      return this.customerInfo.admin_url;
    },
  },
  methods: {
    readableDate(dateStr) {
      const unixTime = Date.parse(dateStr) / 1000;
      return messageTimestamp(unixTime);
    },
  },
};
</script>

<template>
  <div class="order--history">
    <div v-if="!contactAttributes.customer_info">
      <p class="flex h-full items-center flex-col justify-center">
        {{ $t('CONVERSATION_ORDER_HISTORY.NOT_AVAILABLE') }}
      </p>
    </div>
    <div v-else-if="customerInfo.recent_orders">
      <p
        v-if="recentOrders.length === 0"
        class="flex h-full items-center flex-col justify-center"
      >
        {{ $t('CONVERSATION_ORDER_HISTORY.NO_ORDERS') }}
      </p>
    </div>
    <div
      v-for="order in recentOrders"
      :key="order.id"
      class="order--history--item"
    >
      <div class="items-center flex group gap-2 justify-between mb-1.5">
        <a
          :href="order.admin_url"
          target="_blank"
          rel="noopener noreferrer"
          class="flex items-center gap-2 hover:underline text-woot-400 dark:text-woot-600 group-hover:bg-slate-50 group-hover:dark:bg-slate-700"
        >
          <emoji-or-icon
            emoji="📄"
            icon="document"
            icon-size="14"
            class="ltr:ml-1 rtl:mr-1 flex-shrink-0"
          />
          <div class="overflow-hidden whitespace-nowrap text-ellipsis text-sm">
            {{ order.id }}
          </div>
        </a>
      </div>
      <div class="flex items-center break-words pl-4">
        <emoji-or-icon
          emoji="💰"
          icon="money-calculator"
          icon-size="12"
          class="ltr:ml-1 rtl:mr-1 flex-shrink-0"
        />
        <div class="pl-2">{{ order.total_amount }} Kč</div>
      </div>
      <div v-if="order.created_at" class="flex items-center break-words pl-4">
        <emoji-or-icon
          emoji="📆"
          icon="calendar"
          icon-size="12"
          class="ltr:ml-1 rtl:mr-1 flex-shrink-0"
        />
        <div class="pl-2">{{ readableDate(order.created_at) }}</div>
      </div>
      <div v-if="order.items" class="flex items-center break-words pl-4">
        <emoji-or-icon
          emoji="📦"
          icon="cart"
          icon-size="12"
          class="ltr:ml-1 rtl:mr-1 flex-shrink-0"
        />
        <div class="pl-2 overflow-hidden whitespace-nowrap">
          {{ order.items[0].name }}
          <span v-if="order.items.length > 1">
            +
            {{ order.items.length - 1 }}
            {{ $t('CONVERSATION_ORDER_HISTORY.MORE_ITEMS') }}
          </span>
        </div>
      </div>
      <div v-if="order.status" class="flex items-center break-words pl-4">
        <emoji-or-icon
          emoji="⏺"
          icon="circle"
          icon-size="12"
          class="ltr:ml-1 rtl:mr-1 flex-shrink-0"
        />
        <div class="pl-2">{{ order.status }}</div>
      </div>
    </div>
    <div
      v-if="profileUrl"
      class="items-center flex group justify-between mb-1.5 py-2"
    >
      <a
        :href="profileUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="text-woot-400 dark:text-woot-600 group-hover:bg-slate-50 group-hover:dark:bg-slate-700 inline-block rounded-sm mb-0 break-all py-0.5 px-1"
      >
        {{ $t('CONVERSATION_ORDER_HISTORY.EXPAND') }}
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.order--history--item {
  @apply border-slate-50 dark:border-slate-700/50 border-b border-solid p-2;
  &:nth-child(2n) {
    @apply bg-slate-25 dark:bg-slate-800/50;
  }
}
</style>
