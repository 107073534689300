<script>
import { useAlert } from 'dashboard/composables';
import FormButton from 'v3/components/Form/Button.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    FormButton,
  },
  props: {},
  data() {
    return {
      enteredUsername: '',
      enteredPassword: '',
      enteredExtensions: [],
      inputStyles: {
        borderRadius: '12px',
        padding: '6px 12px',
        fontSize: '14px',
        marginBottom: '2px',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'userPhoneCredentials/getUIFlags',
      credentials: 'userPhoneCredentials/getCredentials',
    }),
    inboxes() {
      return this.$store.getters['inboxes/getInboxes'];
    },
  },
  validations: {},
  watch: {
    credentials(record) {
      this.enteredUsername = record.username;
      this.enteredPassword = record.password;
      this.enteredExtensions = record.extensions;
    },
  },
  mounted() {
    this.$store.dispatch('userPhoneCredentials/get');
  },
  methods: {
    async updateCredentials() {
      let alertMessage = '';
      try {
        await this.$store.dispatch('userPhoneCredentials/update', {
          username: this.enteredUsername,
          password: this.enteredPassword,
          extensions: this.enteredExtensions,
        });
        alertMessage = this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS');
      } catch (error) {
        alertMessage = this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR');
      } finally {
        useAlert(alertMessage);
      }
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="updateCredentials()">
    <woot-input
      v-model="enteredUsername"
      :styles="inputStyles"
      :label="$t('PROFILE_SETTINGS.FORM.PHONE_SETTINGS.USERNAME')"
    />
    <woot-input
      v-model="enteredPassword"
      type="password"
      :styles="inputStyles"
      :label="$t('PROFILE_SETTINGS.FORM.PHONE_SETTINGS.PASSWORD')"
    />
    <div class="mb-6">
      <span>
        {{ $t('PROFILE_SETTINGS.FORM.PHONE_SETTINGS.EXTENSIONS') }}
      </span>
      <input
        v-for="(ext, index) in enteredExtensions"
        :key="index"
        :value="ext"
        class="bg-white dark:bg-slate-900 text-slate-900 dark:text-slate-100 border-slate-200 dark:border-slate-600"
        type="text"
        @input="enteredExtensions[index] = $event.target.value"
      />
      <button class="add-button" @click.prevent="enteredExtensions.push('')">
        <fluent-icon icon="add" class="fluent-icon" size="16" />
      </button>
    </div>
    <FormButton
      type="submit"
      color-scheme="primary"
      variant="solid"
      size="large"
      :disabled="uiFlags.isUpdating"
    >
      {{ $t('PROFILE_SETTINGS.FORM.PHONE_SETTINGS.SUBMIT') }}
    </FormButton>
  </form>
</template>
